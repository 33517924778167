<template>
  <div class="cart">
    <div class="main size-16 weight-700" style="padding: 0 24px">장바구니</div>
    <div class="cart-content">
      <div class="service-info">
        <img src="/static/icon/u_browser.svg" class="svg-sub2"
             style="width:17px;height:17px;vertical-align: sub;margin-right:8px">
        <span class="subtitle6 sub2">{{ service.service_name }}</span>
        <div class="subtitle7 sub3">{{ service.key }}.launchpack.co.kr</div>
      </div>

      <div class="sub4 body4-bold cart-sub-title">주문 방식</div>
      <card-cart v-for="(card,idx) in service.conversions" :key="'cart-conversion-'+idx" :card="card"
                 @delete="onConversionDelete(idx)" :closeEnable="false"></card-cart>
      <div class="card-wrapper sub4" v-if="service.conversions.length===0">주문 방식을 선택해주세요.</div>

      <div v-if="service.plugins.length>0" class="sub4 body4-bold cart-sub-title">플러그인</div>
      <card-cart v-for="(card,idx) in service.plugins" :key="'cart-plugin-'+idx" :card="card"
                 @delete="service.plugins.remove(idx)"></card-cart>

    </div>
    <div class="cart-total">
      <div class="flex-between">
        <div class="body3 main">총금액</div>
        <div><span class="price h6 main">{{ totalPrice|currencyNum }}</span>원<!--<span class="sub3">/월</span>--></div>
      </div>
      <!--<button class="button is-dark" style="width:100%;margin-top:24px" @click="$emit('next')">다음</button>-->
    </div>
  </div>
</template>

<script>
  import CardCart from "./CardCart";
  export default {
    name: "Cart",
    components: {CardCart},
    props: {
      service: {
        type: Object
      },
    },
    created() {

    },
    data() {
      return {}
    },
    methods: {
      onConversionDelete(idx) {
        if(this.service.conversions.length === 1) {
          this.toast('주문 방식을 모두 삭제 할 수 없습니다.');
        } else {
          if(this.service.conversions[idx].add_option) {
            this.service.conversions[idx].add_option = false;
          }
          this.service.conversions.remove(idx);
        }
      }
    },
    computed: {
      totalPrice() {
        let price = 0;
        this.service.conversions.forEach(item=>{
          price += item.price.price;
          // 옵션(결제기능) 선택한 경우, 결제기능 금액에 원래 상품 금액 차감하여 더하기
          if(item.add_option) {
            price += (item.option.price.price - item.price.price);
          }
        });
        this.service.plugins.forEach(item=>{ price += item.price.price });
        return price;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .cart
    width 392px
    padding-top 20px
    background-color white
    height calc(100vh - 95px)
    display flex
    flex-direction column
  .cart-sub-title
    margin 32px 0 8px 0
  .cart-content
    flex 1
    overflow-y auto
    padding 0 24px 40px 24px

  .cart-total
    border-top 1px solid $gray1
    padding 20px 24px

  .card-wrapper
    border-top 1px solid #eee
    padding 16px 0
    display flex
    justify-content space-between
    color sub2

  .service-info
    border-radius 8px
    background-color $gray3
    padding 12px 16px
    margin-top 12px
</style>
